import { createContext, useContext, useState } from "react";

const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
  const [productDropDown, setProductDropDown] = useState(false);
  return (
    <MainContext.Provider value={{ productDropDown, setProductDropDown }}>
      {children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => {
  return useContext(MainContext);
};

export default MainContext;
