import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { lazy } from "react";
import Loader from "./components/loader/Loader";
import "./App.css";

const PageNotFound = lazy(() =>
  import("./components/pageNotFound/PageNotFound")
);
const TopNavbar = lazy(() => import("./components/topNavbar/TopNavbar"));
const Navbar = lazy(() => import("./components/navbar/Navbar"));
const Footer = lazy(() => import("./components/footer/Footer"));
const Home = lazy(() => import("./pages/home/Home"));
const About = lazy(() => import("./pages/about/About"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const CompanyTour = lazy(() => import("./pages/companyTour/CompanyTour"));
const TermsOfService = lazy(() =>
  import("./pages/termsOfService/TermsOfService")
);
const PrivacyPolicy = lazy(() =>
  import("./pages/termsOfService/PrivacyPolicy")
);

// Mian Products
const PowderCoatingBooth = lazy(() =>
  import("./pages/product/PowderCoatingBooth")
);
const PaintBooth = lazy(() => import("./pages/product/PaintBooth"));

// Products Detail
const DryTypeSideDraftPaintBooth = lazy(() =>
  import("./pages/productDetails/PB1_DryTypeSideDraftPaintBooth")
);
const WetTypePaintBooth = lazy(() =>
  import("./pages/productDetails/PB2_WetTypePaintBooth")
);
const DownDraftPaintBooth = lazy(() =>
  import("./pages/productDetails/PB3_DownDraftPaintBooth")
);
const ConveyorisedPaintLine = lazy(() =>
  import("./pages/productDetails/PB4_ConveyorisedPaintLine")
);

const BatchTypePowderCoatingPlant = lazy(() =>
  import("./pages/productDetails/PC1_BatchTypePowderCoatingPlant")
);
const ConveyorisedPowderCoatingPlant = lazy(() =>
  import("./pages/productDetails/PC1_ConveyorisedPowderCoatingPlant")
);

const ShotBlastingSystem = lazy(() =>
  import("./pages/productDetails/O1_ShotBlastingSystem")
);
const IndustrialOvens = lazy(() =>
  import("./pages/productDetails/O2_IndustrialOvens")
);
// const FumesExtractionSystem = lazy(() =>
//   import("./pages/productDetails/O3_FumesExtractionSystem")
// );
const PreTreatmentPlants = lazy(() =>
  import("./pages/productDetails/O4_PreTreatmentPlants")
);

const App = () => {
  // useEffect(() => {
  //   const disableRightClick = (e) => {
  //     e.preventDefault();
  //   };
  //   document.addEventListener("contextmenu", disableRightClick);

  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //   };
  // }, []);

  return (
    <HelmetProvider>
      <div>
        <Suspense fallback={<Loader />}>
          <TopNavbar />
          <Navbar />
          <Routes>
            <Route path="/*" element={<PageNotFound />} />{" "}
            <Route exact path="/" element={<Home />} />{" "}
            <Route path="/about" element={<About />} />{" "}
            <Route path="/contact" element={<Contact />} />{" "}
            {/* <Route path="/company_tour" element={<CompanyTour />} />{" "} */}
            <Route path="/terms_services" element={<TermsOfService />} />{" "}
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />{" "}
            {/* Main Products */}{" "}
            <Route path="/paint_booth" element={<PaintBooth />} />{" "}
            <Route
              path="/powder_coating_booth"
              element={<PowderCoatingBooth />}
            />{" "}
            {/* Paint Booth Products */}{" "}
            <Route
              path="/dry_type_side_draft_paint_booth"
              element={<DryTypeSideDraftPaintBooth />}
            />{" "}
            <Route
              path="/wet_type_paint_booth"
              element={<WetTypePaintBooth />}
            />{" "}
            <Route
              path="/down_draft_paint_booth"
              element={<DownDraftPaintBooth />}
            />{" "}
            <Route
              path="/conveyorised_paint_line"
              element={<ConveyorisedPaintLine />}
            />{" "}
            {/* Powder Coating Booth Products */}{" "}
            <Route
              path="/batch_type_powder_coating_plant"
              element={<BatchTypePowderCoatingPlant />}
            />{" "}
            <Route
              path="/conveyorised_powder_coating_plant"
              element={<ConveyorisedPowderCoatingPlant />}
            />{" "}
            {/* Other Products */}{" "}
            <Route
              path="/shot_blasting_system"
              element={<ShotBlastingSystem />}
            />{" "}
            <Route path="/industrial_oven" element={<IndustrialOvens />} />{" "}
            <Route
              path="/pre_treatment_plant"
              element={<PreTreatmentPlants />}
            />{" "}
            {/* <Route
                                                  path="/fumes_extraction_system"
                                                  element={<FumesExtractionSystem />}
                                                /> */}{" "}
          </Routes>{" "}
          <Footer />
          {/* <SocialMediaBox /> */}
        </Suspense>{" "}
      </div>{" "}
    </HelmetProvider>
  );
};

export default App;
