import { createContext, useState, useEffect, useContext } from "react";

const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [loadedImages, setLoadedImages] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const imageUrls = {
    // home slider images
    videoPreloadImg: require("../assets/images/home/pre-load.png"),
    homeAboutLeft: require("../assets/images/home/home-abouts.jpg"),
  };

  useEffect(() => {
    let isMounted = true;
    const loadImages = Object.entries(imageUrls).map(
      ([key, url]) =>
        new Promise((resolve) => {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            if (isMounted) {
              setLoadedImages((prev) => ({ ...prev, [key]: url }));
            }
            resolve();
          };
          img.onerror = resolve;
        })
    );

    Promise.all(loadImages).then(() => {
      if (isMounted) {
        setIsLoaded(true);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <ImageContext.Provider value={{ images: loadedImages, isLoaded }}>
      {children}
    </ImageContext.Provider>
  );
};

export const useImages = () => useContext(ImageContext);
