import "./loader.css";

// image
import logo from "../../assets/images/logo.png";

const Loader = () => {
  return (
    <div className="lazy-loading-main">
      <img src={logo} alt="loading" title="loading" className="img-fluid" />
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
